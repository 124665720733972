















import { Component, Vue } from "vue-property-decorator";
import { ClassColumnDataTable } from "@/components/DatatableGenerico/ClassColumnDataTable";
import { datatypes } from "@/components/DatatableGenerico/datatypes";
import alimentoModule from "@/store/modules/visualizacion_dieta/alimento-module";
import { RouterNames } from "@/router/routernames";

@Component({
  components: {
    DataTable: () => import("@/components/DatatableGenerico/DataTable.vue"),
  },
})
export default class alimentos_lista extends Vue {
  public created() {
    alimentoModule.getalimentos();
  }
  public get source() {
    return alimentoModule.alimentos;
  }

  public get Columns() {
    var Columnas: ClassColumnDataTable[] = [];

    Columnas.push(
      new ClassColumnDataTable(
        "nombre",
        "Nombre",
        datatypes.string,
        true,
        datatypes.maxlength
      )
    );
    return Columnas;
  }

  public editar(e: any) {
    if ((process.env.VUE_APP_PRODUCTION as string) === "true") {
      window.open(
        "https://app.mymedicaldiet.es/#/main/admin/alimentos/alimento/" +
          e.toString()
      );
    } else {
      window.open(
        " http://localhost:8080/#/main/admin/alimentos/alimento/" + e.toString()
      );
    }

    /*this.$router.push({
      name: RouterNames.adminalimentos_edit,
      params: { id: e.toString() },
    });*/
  }
}
